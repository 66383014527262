import React from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

import OTTAndarBahar from "../../../../assets/images/andarbahar/Ott-andar-bahar.jpg";
import AndarBahar from "../../../../assets/images/andarbahar/Andar-bahar.jpg";
import UltimateAndarBahar from "../../../../assets/images/andarbahar/Ultimate-Andar-Bahar.jpg";

import AndarBaharSupernowa from "../../../../assets/images/andarbahar/Andar-BaharSupernowa.jpg";
import ClassicAndarBaharSupernowa from "../../../../assets/images/andarbahar/Classic-Andar-Bahar.jpg";
import GoasAndarBaharSupernowa from "../../../../assets/images/andarbahar/Goa's-Andar-Bahar.jpg";
import RNGAndarBaharSupernowa from "../../../../assets/images/andarbahar/RNG-Andar-Bahar-2020.jpg";

const FirstGamePage = () => {
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <main className="main">
        <div className="container">
          <div className="game-page">
            <h2 class="sectionTitle">Andar Bahar</h2>
            <div className="provider-game-name">
              <h3>Ezugi</h3>
            </div>
            <div className="provider-game-section">
              <ul>
                <li>
                  <a href="/casino/ezugi/Andarbahar">
                    <img src={AndarBahar} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/UltimateAndarbahar">
                    <img src={UltimateAndarBahar} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/OTTAndarbahar">
                    <img src={OTTAndarBahar} />
                  </a>
                </li>
              </ul>
            </div>

            <div className="provider-game-name">
              <h3>Boss11</h3>
            </div>
            <div className="provider-game-section">
              <ul>
                <li>
                  <a href="/casino/ezugi/ABSupernowa">
                    <img src={AndarBaharSupernowa} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/ABClassicSupernowa">
                    <img src={ClassicAndarBaharSupernowa} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/GoaABSupernowa">
                    <img src={GoasAndarBaharSupernowa} />
                  </a>
                </li>
                {/* <li>
                  <a href="/casino/sn-rng-andar-bahar-2020">
                    <img src={RNGAndarBaharSupernowa} />
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default FirstGamePage;
