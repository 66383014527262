import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Nav, Navbar } from "react-bootstrap";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/images/telegramIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIcon.svg";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
// import EyeIcon from "../assets/svg/EyeIcon";
import logo from "../assets/images/logo.png";
import { useSelector } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import MenuIcon from "../assets/images/menu-icon.png";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import LoginModal from "../views/components/authModals/LoginModal";
import { APP_CONST } from "../config/const";
import { CustomView, isMobile } from "react-device-detect";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";
import ForgotPasswords from "../views/components/authModals/ForgotPassword";
import ResetPassword from "../views/components/authModals/ResetPassword";
const Header = () => {
  console.log("Mobile", isMobile);
  // console.log("Mobile check");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
    forgotpassword: false,
    resetpassword: false,
  });
  const handleShow1 = () => setShow1(!show1);
  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
  }, []);
  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = [
    "home",
    "sports",
    "public-sports",
    "live-dealer",
    "evolution",
    "aviator",
    "sign-in",
    "/",
  ];
  useEffect(() => {
    const matchedValue = headerPages.find((f) => {
      if (location?.pathname == "/public-sports") {
        return f === location?.pathname.split("/")?.[1];
      } else {
        return location?.pathname.includes(f);
      }
    });
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <header className="header header_bfrlgn">
      <div className="container">
        <div className="topHead">
          <Button
            variant="primary menu-btn"
            onClick={handleShow1}
            className="d-block d-md-none"
          >
            <img src={MenuIcon} alt="User Menu Icon" width={25} />
          </Button>

          <div className="logo">
            <a href="/">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
            </a>
          </div>

          <div className="header-left mx-lg-auto d-none d-xl-block">
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="d-none"
            />
            <Navbar.Collapse id="basic-navbar-nav" className="d-block">
              <Nav className="me-auto">
                {/* {<Nav.Link href="#home">New Games</Nav.Link>} */}
                <Nav.Link
                  href="/sports"
                  className={
                    activePage == "sports" || activePage === "/" ? "active" : ""
                  }
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  href="/public-sports"
                  className={activePage == "public-sports" ? "active" : ""}
                >
                  Sportsbook
                </Nav.Link>
                <Nav.Link
                  href="/live-dealer-public"
                  className={activePage == "live-dealer" ? "active" : ""}
                >
                  Live Dealers
                </Nav.Link>
                <Nav.Link
                  href="/sign-in"
                  className={activePage == "sign-in" ? "" : ""}
                >
                  Game Shows
                </Nav.Link>
                <Nav.Link
                  href="/casino/spribe/aviator"
                  className={activePage == "sign-in" ? "" : ""}
                >
                  Aviator
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>

          <div className="headerRight ms-auto ms-xl-0">
            <ul className="d-none">
              <li>
                <a
                  className="tg"
                  href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={TelegramIcon} alt="Telegram Icon" />
                </a>
              </li>
              <li>
                <a
                  className="wtp"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <img src={WhatsappIcon} alt="Whatsapp Icon" />
                </a>
              </li>
            </ul>

            <div className="theme-btn me-2">
              <button type="button" className="btn" onClick={toggleClass}>
                <img src={SunLight} alt="sun" className="light_theme" />
                <img src={MoonLight} alt="moon" className="dark_theme" />
              </button>
            </div>

            <Button
              variant="primary"
              // onClick={() => {
              //   navigate("/sign-in");
              // }}
              onClick={() => {
                navigate("/sign-in");
              }}
              className="login_btn me-2"
            >
              Sign In
            </Button>
            <Button
              variant="primary"
              // onClick={() => {
              //   navigate("/mobile-number");
              // }}

              onClick={() => {
                navigate("/mobile-number");
              }}
              className="signup_btn"
            >
              Sign Up
            </Button>
            <ul>
              <li className="ms-2">
                <GamesSearchModal />
              </li>
            </ul>
            {/* <div className="searchbar">
              <AiOutlineSearch />
            </div> */}
          </div>
        </div>
      </div>

      <div className="subHead d-none">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ul>
                <li>
                  <a href="#">Exchange</a>
                </li>
                <li>
                  <a href="#">Multi Markets</a>
                </li>
                <li>
                  <a href="/public-sports">Sportsbook</a>
                </li>
                <li>
                  <a href="/public-sports">Virtual Sports</a>
                </li>
                <li>
                  <a href="/live-dealer-public">Indian Casino</a>
                </li>
                <li>
                  <a href="/live-dealer-public">Live Casino</a>
                </li>
                <li>
                  <a href="#">
                    Fun Games <span className="badge">New</span>
                  </a>
                </li>
                <li>
                  <a href="/public-sports">Cricket</a>
                </li>
                <li>
                  <a href="/public-sports" className="cup-color">
                    World Cup
                  </a>
                </li>
                <li>
                  <a href="/public-sports">Football</a>
                </li>
                <li>
                  <a href="/public-sports">Tennis</a>
                </li>
                <li>
                  <a href="/public-sports">Horse Racing</a>
                </li>
                <li>
                  <a href="#">TV Games</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <LoginModal
        show={showAuthModals.login}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
        handleModalShow={handleModalShow}
      />
      {showAuthModals.mobileNumber && (
        <MobileNumberModal
          show={showAuthModals.mobileNumber}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.verifyNumber && (
        <MobileNumberVerifyModal
          show={showAuthModals.verifyNumber}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.register && (
        <RegisterModal
          show={showAuthModals.register}
          handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
          handleModalShow={handleModalShow}
        />
      )}

      <ForgotPasswords
        show={showAuthModals.forgotpassword}
        handleClose={() =>
          handleModalClose(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD)
        }
        handleModalShow={handleModalShow}
      />
      <ResetPassword
        show={showAuthModals.resetpassword}
        handleClose={() =>
          handleModalClose(APP_CONST.AUTH_MODAL.RESET_PASSWORD)
        }
        handleModalShow={handleModalShow}
      />

      {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Log in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
      {show1 && <MobLeftbar className="d-block d-md-none" />}
    </header>
  );
};

export default Header;
